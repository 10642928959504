import { useRouter } from 'next/router';

import { ExternalAuthRedirect } from 'components/account';
import { PageSeo } from 'components/common';
import { Layout } from 'components/layout';
import { Loader } from 'components/ui';
import { TRANSLATIONS } from 'constants/translations';
import { withSSGPage } from 'hoc/WithServerPage/withSSGPage';

import type { NextPageWithLayout } from 'types';
import { PageType } from 'types';

export const getStaticProps = withSSGPage(async () => {
  return {
    props: {}
  };
}, {
  translations: [TRANSLATIONS.COMMON],
  pageType: PageType.Login,
  revalidate: false
});

const Page: NextPageWithLayout<PageType.Login> = () => {
  const router = useRouter();

  const { query } = router;
  const { state } = query;

  return (
    <div>
      <PageSeo seoProps={{ noindex: true }} />
      <div className="overlay flex-center">
        <Loader
          size={24}
          lineWeight={2}
        />
      </div>
      {/* @ts-ignore */}
      <ExternalAuthRedirect state={state} />
    </div>
  );
};

const layoutProps = {
  header: {
    props: {},
    isVisible: false
  },
  footer: {
    props: {},
    isVisible: false
  },
  openInAppBanner: {
    props: {},
    isVisible: false
  }
};

Page.getLayout = (page) => {
  return (
    <Layout {...layoutProps}>
      {page}
    </Layout>
  );
};

export default Page;
